import BaseDataManager from './base';
// import { OnboardCriteriaModel } from 'app/models';
// import { OrganizationLinkedInProfile } from 'app/models/OrganizationLinkedInInformation';
// import { LinkedInTaskManager } from './tasks';
// import { CampaignMember } from '@copilot/common/store/models/redux';
import {
	LinkedinOrganizationMemberResponse,
	EmptyResponse,
	LinkedInOrganizationSettingsResponse,
	LinkedInContactResponse,
} from '../responses/interface';
import {
	InMailSequenceMessage,
	OnboardCriteriaRequestModel,
	OnboardMessage,
	OnboardMessageResponse,
} from '../requests/models';

class LinkedInManager extends BaseDataManager {
	constructor() {
		super();
		this.getInMailSequence = this.getInMailSequence.bind(this);
		this.setInMailSequence = this.setInMailSequence.bind(this);
	}

	/**
	 * Save messaging for a user
	 * @param orgMemberId Organization member id of the user we are saving messages for
	 * @param campaignId Campaign Id for the campaign of the messages
	 * @param messages Message templates we're saving
	 */
	public saveUserMessages(orgMemberId: string, campaignId: string, messages: OnboardMessage[]) {
		if (!orgMemberId || !campaignId) Promise.reject(new Error('Campaign Id not provided.'));
		const url = this.combineRoute(
			this.BACKEND_ROUTES.LINKED_IN.Onboard,
			orgMemberId,
			'messages'
		);
		const payload = { [campaignId]: messages };
		return this.RequestManager.post<EmptyResponse>(url, payload).then(
			(response) => response.data
		);
	}

	/**
	 * Save messaging for a campaign
	 * @param campaignId Campaign Id for the campaign of the messages
	 * @param messages Message templates we're saving
	 */
	public saveCampaignMessages(campaignId: string, messages: OnboardMessage[]) {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.LinkedIn,
			campaignId,
			'messages'
		);
		const payload = { [campaignId]: messages };
		return this.RequestManager.post<EmptyResponse>(url, payload).then(
			(response) => response.data
		);
	}

	/**
	 * Stop Messaging automation
	 * @param campaignId campaignId we are stopping message automation for
	 * @param nodeIds list of nodeIds to terminate connections from
	 */
	public terminateMessageAutomationByCampaign(campaignId: string, nodeIds: string[]) {
		if (!campaignId) return Promise.reject('No id given');

		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.LinkedIn,
			campaignId,
			'terminate'
		);
		return this.RequestManager.post(url, nodeIds).then((response) => response.data);
	}

	/**
	 * Stop Messaging automation
	 * @param campaignId campaignId we are stopping message automation for
	 * @param campaignMemberId campaign Member id
	 * @param nodeIds list of nodeIds to terminate connections from
	 */
	public terminateMessageAutomationByCampaignMember(
		campaignId: string,
		campaignMemberId: string,
		nodeIds: string[]
	) {
		if (!campaignId || !campaignMemberId) return Promise.reject('No id given');
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.LinkedIn,
			campaignId,
			'member',
			campaignMemberId,
			'terminate'
		);
		return this.RequestManager.post(url, nodeIds).then((response) => response.data);
	}

	/**
	 * Stop Messaging automation
	 * @param campaignId campaign id
	 * @param memberId campaign member id
	 * @param connectionIds connections we are stopping message automation for
	 * @param omid org member id (needed for impersonation - only for cs admin)
	 */
	public terminateMessageAutomationByConnections = (
		campaignId: string,
		memberId: string,
		connectionIds: string[],
		omid: string | null = null
	) => {
		if (!campaignId || !memberId || connectionIds.length === 0)
			return Promise.reject('No id given');
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.LinkedIn,
			campaignId,
			'members',
			memberId,
			'connection',
			'terminate'
		);
		return this.RequestManager.post<EmptyResponse>(url, connectionIds, {
			params: { omid },
		}).then((response) => response.data);
	};

	/**
	 * Stop Messaging automation as an Admin
	 * @param campaignId campaign id
	 * @param connectionIds connectionIds we are stopping message automation for
	 * @param omid org member id (needed for impersonation - only for cs admin)
	 */
	public terminateMessageAutomationByAdmin = (
		campaignId: string,
		connectionIds: string[],
		omid: string | null = null
	) => {
		if (!campaignId || connectionIds.length === 0) return Promise.reject('No id given');
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.LinkedIn,
			campaignId,
			'connection',
			'terminate'
		);
		return this.RequestManager.post<EmptyResponse>(url, connectionIds, {
			params: { omid },
		}).then((response) => response.data);
	};

	/**
	 * Get user Linkedin profile of members
	 * @param {string} orgId Organization Id
	 * @param {DataManagerOptions} options
	 * @returns {any} A promise resolving the linkedin profile
	 */
	public getUserLinkedInProfile = (orgId: string, memberId: string) => {
		const url = `${this.BACKEND_ROUTES.LINKED_IN.OrgInfo}/${orgId}/members/${memberId}`;
		return this.RequestManager.get<LinkedinOrganizationMemberResponse>(url).then(
			(response) => response.data
		);
	};

	/**
	 * Get user linkedin profiles for all members in an organization
	 * @param {string} orgId The id of the organization
	 */
	public getUsersLinkedInProfiles = (orgId: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.LINKED_IN.OrgInfo, orgId, 'members');
		return this.RequestManager.get<LinkedinOrganizationMemberResponse[]>(url).then(
			(r) => r.data
		);
	};

	// /**
	//  * Get user Linkedin profile of member in org
	//  * @param {string} orgId Organization Id
	//  * @param {string} memberId Member Id
	//  * @param {DataManagerOptions} options
	//  * @returns {any} A promise resolving the linkedin profile
	//  */
	// getUsersLinkedInProfiles(
	// 	orgId: string,
	// 	options: DataManagerOptions = { loadToStore: true }
	// ): Promise<LinkedinOrganizationMember> {
	// 	let url = `${BACKEND_ROUTES.LinkedInEngine.OrgInfo}/${orgId}/members`;
	// 	return RequestManager.get(url).then((response) => {
	// 		let data = response.data;

	// 		let results = data.map((d: any) => {
	// 			let linkedinProfile = new LinkedinOrganizationMember(d);

	// 			return linkedinProfile;
	// 		});
	// 		if (options.loadToStore) store.dispatch(LinkedInActions.loadLinkedInOrgMember(results));

	// 		return results;
	// 	});
	// }

	/**
	 * Get LinkedIn profile for the given contact
	 * @param {string} orgId id of the organization
	 * @param {string} contactId id of the contact we want to get the LinkedIn profile for
	 */
	getContactLinkedInProfile = async (orgId: string, contactId: string) => {
		const url = `${this.BACKEND_ROUTES.LINKED_IN.OrgInfo}/${orgId}/contacts/${contactId}`;
		const response = await this.RequestManager.get<LinkedInContactResponse>(url);
		return response.data.linkedInProfile;
	};

	/**
	 * Get LinkedIn profiles for the given contacts
	 * @param {string} orgId id of the organization
	 * @param {string[]} contactIds ids of the contacts we want to get LinkedIn profiles for
	 */
	public getContactsLinkedInProfiles = async (orgId: string, contactIds: string[]) => {
		const url = `${this.BACKEND_ROUTES.LINKED_IN.OrgInfo}/${orgId}/contacts`;
		const response = await this.RequestManager.post<LinkedInContactResponse[]>(url, contactIds);
		return response.data;
	};

	/**
	 * Get the linkedin settings of an organization
	 * @param {string} orgId Organization Id
	 */
	public getLinkedInOrganizationSettings = (orgId: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.LINKED_IN.OrgInfo, orgId);
		return this.RequestManager.get<LinkedInOrganizationSettingsResponse>(url).then(
			(r) => r.data
		);
	};

	// /**
	//  * Initialize member linkedin campaign
	//  * @param member Campaign Member we want to initialize
	//  * @param flush whether or not to flush search list
	//  * @param options
	//  * @returns {Promise<any[]} The list of linkedin profile
	//  */
	// initializeLinkedInCampaign(
	// 	member: CampaignMember,
	// 	flush: boolean,
	// 	options: DataManagerOptions = { loadToStore: true }
	// ) {
	// 	let url = this.combineRoute(
	// 		BACKEND_ROUTES.LinkedInEngine.InitMember,
	// 		member.campaignId,
	// 		'members',
	// 		member.id,
	// 		'init'
	// 	);
	// 	return RequestManager.get(url, { params: { flush: flush } }).then((response) => {
	// 		let data = response.data;
	// 		return LinkedInTaskManager.getTaskStatus(data);
	// 	});
	// }

	/**
	 * Checks whether the user can onboard
	 * @param orgMemberId orgmemberId
	 * @returns
	 */
	canOnboard(orgMemberId: string) {
		const url = this.combineRoute(this.BACKEND_ROUTES.LINKED_IN.Onboard, orgMemberId);
		return this.RequestManager.get(url).then((response) => {
			const { data } = response;
			return data;
		});
	}

	/**
	 * Initialize a member in a linkedin campaign
	 * @param {stringg} campaignId The id of the campaign
	 * @param {string} memberId The id of the campaign member
	 * @param {boolean} flush Whether to flush the list or not
	 * @param {boolean} includeExistingConnections Whether to include existing connections (for nurture list)
	 */
	public initializeLinkedInCampaign = (
		campaignId: string,
		memberId: string,
		flush: boolean,
		includeExistingConnections = false
	) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.LINKED_IN.InitMember,
			campaignId,
			'members',
			memberId,
			'init'
		);
		return this.RequestManager.get<string>(url, {
			params: { flush, includeExistingConnections },
		}).then((r) => r.data);
	};

	/**
	 * Save onboarding criteria
	 * @param {string} orgMemberId Id of the organization member
	 * @param {OnboardCriteriaRequestModel} criteria The criteria we want to save
	 */
	public saveOnboardingCriteria = (
		orgMemberId: string,
		criteria: OnboardCriteriaRequestModel
	) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.LINKED_IN.Onboard, orgMemberId);
		return this.RequestManager.post<EmptyResponse>(url, criteria).then(
			(response) => response.data
		);
	};

	/**
	 * Reject a connection
	 * @param campaignId Campaign Id of the connection
	 * @param memberId Campaign member connected to the connection
	 * @param connectionId The Id of the connection
	 */
	public rejectConnection = async (
		campaignId: string,
		memberId: string,
		connectionId: string
	) => {
		if (!campaignId || !memberId || !connectionId) throw new Error('Bad Request');
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.LinkedIn,
			campaignId,
			'members',
			memberId,
			'connection',
			connectionId,
			'reject'
		);
		return this.RequestManager.post<EmptyResponse>(url);
	};

	/**
	 * The room name
	 * @param orgMemberId The org member id we want to login for
	 * @returns The room name
	 */
	public getLoginRoomName = async (orgMemberId: string) => {
		if (!orgMemberId) throw new Error('OrgMemberId required');
		const url = this.BACKEND_ROUTES.LINKED_IN.LoginRoomName;
		const result = await this.RequestManager.post<string>(url, {
			memberId: orgMemberId,
		});
		return result.data;
	};

	/**
	 * Get the inmail sequence of a campaign
	 * @param campaignId The campaign to retrieve for
	 * @returns The inmail sequence
	 */
	async getInMailSequence(campaignId: string): Promise<InMailSequenceMessage[]> {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.LinkedIn,
			campaignId,
			'inmailsequence'
		);
		const results = await this.RequestManager.get<
			OnboardMessageResponse<InMailSequenceMessage>
		>(url);
		return results.data[campaignId];
	}

	/**
	 * Update the inmail sequence of a campaign
	 * @param campaignId The campaign to update
	 * @param sequence The inmail sequence to update to
	 * @returns
	 */
	async setInMailSequence(campaignId: string, sequence: InMailSequenceMessage[]) {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.LinkedIn,
			campaignId,
			'inmailsequence'
		);
		return this.RequestManager.post<EmptyResponse>(url, sequence).then(
			(response) => response.data
		);
	}
}

const instance = new LinkedInManager();
export default instance;
