import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Card, Popconfirm } from 'antd';
import { useHistory } from 'react-router';
import SearchUrlSection from '@copilot/common/components/editors/searchUrl/searchUrl';
import { formatName, isSearchUrlValid } from '@copilot/common/utils';
import { ConnectionStatusType, SearchUrlType } from '@copilot/common/utils/constant';
import {
	useCampaignMemberLinkedInfoFetch,
	useUpdateLinkedInInfoFetch,
} from '@copilot/common/hooks/requests/campaignMember';
import modalManager from '@copilot/common/utils/modalManager';
import { CampaignMemberManager, LinkedInManager, OrganizationMemberManager } from '@copilot/data';
import DrawerManager from '@copilot/common/utils/drawerManager';
import { useFetch } from '@copilot/common/hooks/common';
import notificationManager from '@copilot/common/utils/notificationManager';
import ProspectTable, {
	ProspectTableData,
} from '@copilot/common/components/tables/tableTypes/prospect';
import {
	ProspectTableType,
	SelectableTable,
} from '@copilot/common/components/tables/tableTypes/prospect/const';
import { useTableSearchWithTableChange } from '@copilot/common/components/tables/hooks';
import { useSelector } from 'react-redux';
import { LinkedinSelectors } from '@copilot/common/store/selectors/linkedin';
import { ProspectRecord } from '@copilot/common/components/tables/tableTypes/prospect/columns';
import { ConnectionResponse, Features } from '@copilot/data/responses/interface';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { NotificationContent } from '@copilot/common/constant/notificationContent';
import { useTerminateAutomation } from '@copilot/common/hooks/campaign';
import { CustomFieldActions } from '@copilot/common/components/componentModels/columnTypeDefinition';
import { Config } from '@copilot/common/config';
import { useViewProspectDrawerTracking } from '@copilot/common/components/drawer/wrappers/contact/tracking';
import InstructionSteps from '@copilot/common/components/instructionSteps';
import { useIntercom } from 'react-use-intercom';
import { addLink } from '@copilot/common/utils/common';
import { LoggedOutLIWarning } from '../const';
import { SearchInputPlaceholder } from '@copilot/common/components/search/input';
import TeamMemberDropdownWithTitle from '@copilot/common/pages/campaignDashboard/teamMemberDropDown';
import {
	ICampaignDashboardSearchListBaseProps,
	MANAGE_SEARCH_URL_STEPS_TITLE,
	ManageSearchUrlInstructionSteps,
} from '@copilot/common/pages/campaignDashboard/searchList/const';
import { getName } from '@copilot/common/utils/campaignMember/models';
import EmptyNurtureListPlaceholder from './emptyNurtureListPlaceholder';
import { usePermission, validateIsEditable } from '@copilot/common/hooks/permission';
import { PermissionName } from '@copilot/common/hooks/permission/interface';
import { useSearchListTracking } from '@copilot/common/pages/campaignDashboard/searchList/tracking';
import { OrgRoleTitles } from '@copilot/common/store/models/const/enum';
import { LinkedInMetaActions } from '@copilot/common/store/actions/linkedInMeta';
import { QueryObject } from '@copilot/data/managers/base';
import useCampaignMemberSelection from '../searchList/campaignDashboardSearchList/useCampaignMemberSelection';
import { LoadingPane } from '@copilot/common/components/loadingPane/loadingPane';
import useSearchTaskMonitor from '../useSearchListTaskMonitor';
import { getConnectionStatus, getConnectionStatusV2 } from './helperFunctions';
import { NurtureTableColumns, TERMINATE_NODE } from './consts';
import isNil from 'lodash/isNil';
import styles from './nurtureList.module.less';
import { getCommonSearchMonitorConfig } from '../searchListCommon/helperFunctions';
import { SEARCH_STATUSES } from '@copilot/common/components/editors/searchUrl/update/consts';
import isEmpty from 'lodash/isEmpty';
import { useNurtureSearchListV2 } from '@copilot/common/pages/campaignDashboard/nurtureList/useNurtureSearchListV2';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { SearchListTableData } from '@copilot/common/pages/campaignDashboard/searchList/table';

type SearchFuncParams = {
	campaignId: string;
	campaignMemberId: string;
	connectionStatus: ConnectionStatusType;
	orgMemberId?: string;
	searchTerm?: string | null;
	query?: QueryObject;
	isOpenProfile?: boolean;
};

/**
 * Tab container holding nurture list components
 */
export default function CampaignDashboardNurtureList(props: ICampaignDashboardSearchListBaseProps) {
	const isBlocklistRefactorEnabled = useFeatureToggle(Features.DoNotProspectRefactorFeature);
	const isSearchFeatureOn = useFeatureToggle(Features.RunSearchListFeature);
	const { campaignId, isTeamUser, onSearchListStatusChange } = props;
	const {
		campaignMemberOptions,
		campaignMember,
		onCampaignMemberSelect,
		isLoadingCampaignMembers,
	} = useCampaignMemberSelection(props);

	const updateCampaignSearchListTracking = useSearchListTracking(
		'Campaign Dashboard Nurture List',
		null
	);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const isAdmin = !!useSelector(OrganizationMemberSelectors.getAdminMember);
	const disabledWarning = useMemo(
		() =>
			isAdmin
				? LoggedOutLIWarning(activeMember?.isLiSessionInRetryState)
				: addLink(
						LoggedOutLIWarning(activeMember?.isLiSessionInRetryState),
						campaignMember?.orgMemberId === activeMember?.id
							? '/settings/linkedin'
							: `/?tab=teammembers&teamMembersRedirectMemberId=${campaignMember?.orgMemberId}`
				  ),
		[isAdmin, campaignMember, activeMember]
	);
	const history = useHistory();
	const { startTour } = useIntercom();
	const omid = useMemo(
		() => (isAdmin && campaignMember?.orgMemberId ? campaignMember.orgMemberId : undefined),
		[isAdmin, campaignMember?.orgMemberId]
	);
	// [TODO] Save search list to store
	const [, fetchIsLoggedIn] = useFetch(
		OrganizationMemberManager.checkIsLoggedIn,
		LinkedInMetaActions.loadLinkedInMeta,
		(r, orgMemberId) => ({ orgMemberId, isLoggedIn: r })
	);
	async function fetchFunc(args: SearchFuncParams) {
		const result = await CampaignMemberManager.getSearchListResultsByMember(args);

		return {
			...result,
			results: result.results.map((d: ConnectionResponse) => ({
				...d,
				isRemoveHidden: d.currentStep === TERMINATE_NODE,
				connectionStatus: getConnectionStatus(d),
				orgMemberId: campaignMember?.orgMemberId,
				dateCreated: new Date(d.dateCreated),
				name: formatName(d.firstName, d.lastName),
			})),
		};
	}
	const fetchSearchList = useCallback(
		async (
			searchQuery: string | null,
			newQuery: QueryObject | undefined,
			searchFunc: (args: SearchFuncParams) => ReturnType<typeof fetchFunc>
		) => {
			if (campaignId && campaignMember?.id) {
				await searchFunc({
					campaignId,
					campaignMemberId: campaignMember.id,
					connectionStatus: ConnectionStatusType.Connected,
					orgMemberId: omid,
					searchTerm: searchQuery,
					query: newQuery,
				});
			}
			if (campaignMember?.orgMemberId) fetchIsLoggedIn(campaignMember.orgMemberId);
		},
		[campaignId, campaignMember?.id, campaignMember?.orgMemberId, omid]
	);

	const [
		setSearchTerm,
		searchTerm,
		handleTableChange,
		pagination,
		fetchMemberSearchList,
		memberSearchList,
	] = useTableSearchWithTableChange(fetchSearchList, fetchFunc);

	const {
		data: searchListV2,
		loading: isSearchListV2Loading,
		initSearchList,
		refetchSearchList,
		onUpdateSearchTerm,
		onUpdatePagination,
	} = useNurtureSearchListV2();

	const campaignMemberName = useMemo(
		() => (campaignMember ? getName(campaignMember) : undefined),
		[campaignMember]
	);
	const [searchListPermission] = usePermission(PermissionName.SearchList);
	const [ownSearchListPermission] = usePermission(PermissionName.OwnSearchList);
	const isAdvancedTeamUser =
		isTeamUser && activeMember?.orgRoles.includes(OrgRoleTitles.Advanced);
	const canEditSearchList = useMemo(
		() => validateIsEditable(searchListPermission | ownSearchListPermission),
		[searchListPermission, ownSearchListPermission]
	);

	const [, fetchLinkedInInfo] = useCampaignMemberLinkedInfoFetch();
	const [updateLinkedInFetch, updateLinkedInInfo] = useUpdateLinkedInInfoFetch();

	const terminateAutomation = useTerminateAutomation(
		campaignId,
		NotificationContent.RemoveBatchAutomation,
		campaignMember?.id
	);

	const linkedInMeta = useSelector((state) =>
		LinkedinSelectors.getOrgMemberLinkedInMeta(state, campaignMember?.orgMemberId)
	);
	const [campaignInitializer, initializeCampaign] = useFetch(
		LinkedInManager.initializeLinkedInCampaign
	);

	const updateTrackingParams = useViewProspectDrawerTracking('Campaign NurtureList', null);

	const { searchStatus, startMonitoringTaskStatus, hasSearchListStatusBeenChecked } =
		useSearchTaskMonitor({
			orgMemberId: campaignMember?.orgMemberId,
			campaignId,
			...getCommonSearchMonitorConfig(
				isBlocklistRefactorEnabled ? refetchSearchList : fetchMemberSearchList,
				onSearchListStatusChange
			),
		});

	useEffect(() => {
		if (campaignMember?.id) {
			fetchLinkedInInfo(campaignId, campaignMember?.id);
		}

		// Load search list using nurture search list v2 if do not prospect refactor is enabled
		if (isBlocklistRefactorEnabled) {
			initSearchList(campaignMember);
		}
	}, [campaignId, campaignMember?.id]);

	/**
	 * Callback for opening the modal for search building help
	 */
	function handleSearchVideoLinkClick() {
		modalManager.openVideoModal({
			videoType: 'NurtureSearchVideo',
			width: 800,
			centered: true,
			hideFooter: true,
		});
	}
	/**
	 * Callback for saving a search url and then performing a search
	 * @param url The url we want to update to
	 * @param includeExistingConnections whether we want to include existing connections from other campaigns to the nurture campaign
	 */
	async function handleSaveAndSearch(url: string, includeExistingConnections: boolean) {
		if (!campaignId || !campaignMember?.id)
			throw new Error('Campaign or campaign id not found.');
		if (url === campaignMember.searchUrl) {
			return notificationManager.showWarnNotification({
				message: 'Search Error',
				description:
					'Your new search url is identical to the old search url. Search has not been performed.',
				duration: 5,
			});
		}
		if (!isAdmin && isTeamUser) {
			updateCampaignSearchListTracking({
				buttonClicked: 'Search List Tab Search - All Team Users',
			});
			if (isAdvancedTeamUser) {
				updateCampaignSearchListTracking({
					buttonClicked: 'Search List Tab Search - Advanced Team User',
				});
			}
		}
		await updateLinkedInInfo(campaignId, campaignMember.id, { searchUrl: url });
		try {
			await initializeCampaign(
				campaignId,
				campaignMember.id,
				false,
				includeExistingConnections
			); // don't want to flush the list
			startMonitoringTaskStatus();
			onSearchListStatusChange?.(SEARCH_STATUSES.SEARCHING_CURRENT_CAMPAIGN);
			notificationManager.showSuccessNotification({
				message: 'Search Saved',
				description: 'Your search url has been saved and a search has been started.',
			});
		} catch (err: any) {
			const isConflict = typeof err === 'object' && err != null && err.status === 409;
			notificationManager.showErrorNotification({
				message: 'Search Error',
				description: isConflict
					? 'You are already running a search in one of your campaigns. Please wait until your search finishes.'
					: 'Unable to perform search.',
				duration: 8,
			});
		}
		return undefined;
	}

	const isInitialSearch =
		isNil(campaignMember?.lastSearchUrlUpdate) && isNil(campaignMember?.searchUrl);

	/**
	 * Button and confirmation popup for performing a search
	 * @param searchUrl the search url we will search for
	 */
	const saveAndSearchButton = useCallback(
		(searchUrl: string) => {
			const isSearchDisabled =
				!isSearchFeatureOn ||
				!linkedInMeta?.isLoggedIn ||
				!isSearchUrlValid(searchUrl) ||
				searchStatus == SEARCH_STATUSES.SEARCHING_CURRENT_CAMPAIGN;
			const performSearchText = isSearchFeatureOn ? 'Perform Search' : 'Under Maintenance';
			const isSaving = campaignInitializer?.isFetching || updateLinkedInFetch?.isFetching;
			return (
				<Popconfirm
					title={
						<div className={styles.connectionMovementTitle}>
							Do you want to move any of your connections from other campaigns over to
							this nurture campaign?
						</div>
					}
					onConfirm={() => {
						handleSaveAndSearch(searchUrl, true);
					}}
					onCancel={() => {
						handleSaveAndSearch(searchUrl, false);
					}}
					okText="Yes"
					cancelText="No"
					disabled={isSearchDisabled}
				>
					<Button type="primary" disabled={isSearchDisabled} loading={isSaving}>
						{isSaving ? 'Saving' : performSearchText}
					</Button>
				</Popconfirm>
			);
		},
		[
			handleSaveAndSearch,
			campaignMember,
			memberSearchList?.data?.totalCount,
			linkedInMeta?.isLoggedIn,
			campaignInitializer?.isFetching,
			searchStatus,
		]
	);

	/**
	 * Callback for when a table row is clicked
	 * @param record Table Data of the row that was clicked
	 */
	function handleTableRowClick(record: ProspectTableData) {
		if (record.orgMemberId) {
			DrawerManager.openContactDrawer({
				id: record.contactId,
				memberId: record.orgMemberId,
			});
			updateTrackingParams({ buttonClicked: 'Nurture Campaign Nurture List Row' });
		}
	}

	/**
	 * Callback for removing connections from the campaign
	 * @param connectionIds The ids of the connection we want to remove
	 */
	async function removeConnection(connectionIds: string[]) {
		await terminateAutomation(connectionIds);
		// TODO: remove the fetch after the search list is saved to the store
		isBlocklistRefactorEnabled ? refetchSearchList() : fetchMemberSearchList();
	}

	/**
	 * Callback for updating the table (search list v2)
	 * @param paginationUpdate
	 * @param filtersUpdate
	 * @param sorterUpdate
	 */
	function onUpdateTable(
		paginationUpdate: TablePaginationConfig,
		filtersUpdate: Record<string, FilterValue | null>,
		sorterUpdate: SorterResult<SearchListTableData> | SorterResult<SearchListTableData>[]
	) {
		if (isBlocklistRefactorEnabled) {
			onUpdatePagination(paginationUpdate.pageSize, paginationUpdate.current);
		} else {
			handleTableChange(paginationUpdate, filtersUpdate, sorterUpdate);
		}
	}

	const determineLinkDisplay = (actionType: CustomFieldActions, record: string[]) => {
		if (record) {
			const connectionId = record[ProspectRecord.connectionId];
			const isRemoveHidden = record[ProspectRecord.isRemoveHidden] ?? false;
			if (actionType == CustomFieldActions.Remove && !!connectionId && !isRemoveHidden) {
				return (
					<Popconfirm
						title={
							<div className={styles.pauseAutomationTitle}>
								Are you sure you wish to stop automation for this prospect?
							</div>
						}
						onConfirm={(e) => {
							e?.stopPropagation();
							removeConnection([connectionId]);
						}}
						onCancel={(e) => e?.stopPropagation()}
						okText="Yes"
						cancelText="No"
					>
						<Button
							type="link"
							style={{ padding: 0 }}
							onClick={(e: React.MouseEvent) => e.stopPropagation()}
						>
							Stop Automation
						</Button>
					</Popconfirm>
				);
			}
		}
		return null;
	};

	/**
	 * Callback for clicking the "Get Started" button on the instruction steps for empty nurture lists
	 */
	function handleInstructionsGetStartedClick() {
		const tourId = Config.NurtureConnectionsProductTourId;
		if (!Config.isAgency && !!tourId) {
			startTour(tourId);
		} else {
			history.push('/connections');
		}
	}

	function getSearchUrlTitle() {
		if (isTeamUser && !isEmpty(campaignMember?.firstName)) {
			return `${campaignMember?.firstName}'s Search URL`;
		} else return 'Import Connections with Sales Navigator URL';
	}

	function getSearchTableTitle() {
		if (isTeamUser && !isEmpty(campaignMember?.firstName)) {
			return `${campaignMember?.firstName}'s Nurture List`;
		} else return 'Nurture List';
	}

	function getPagination(): TablePaginationConfig {
		if (isBlocklistRefactorEnabled) {
			return {
				current: searchListV2.pages.current,
				total: searchListV2.count,
			};
		} else {
			return pagination;
		}
	}

	function getNurtureSearchListData(): ProspectTableData[] {
		if (isBlocklistRefactorEnabled) {
			return searchListV2.content.map((data) => ({
				...data,
				isRemoveHidden: isNil(data.nextAutomationNode),
				connectionStatus: getConnectionStatusV2(data),
				orgMemberId: campaignMember?.orgMemberId,
			}));
		} else {
			return memberSearchList.data?.results ?? [];
		}
	}

	const showSelectMemberInstructions = !campaignMember && campaignMemberOptions;

	const showEmptyPlaceholder =
		!memberSearchList?.isFetching &&
		memberSearchList.data?.totalCount === 0 &&
		memberSearchList.data?.results &&
		!searchTerm &&
		!isAdmin;

	if (isLoadingCampaignMembers) {
		return <LoadingPane isLoading />;
	}

	return (
		<>
			{canEditSearchList && (
				<Card className={styles.searchUrlModule}>
					{campaignMemberOptions && (
						<TeamMemberDropdownWithTitle
							selectedMemberName={campaignMemberName}
							options={campaignMemberOptions}
							onSelect={onCampaignMemberSelect}
						/>
					)}
					{!showSelectMemberInstructions && (
						<div className={styles.searchListTitle}>{getSearchUrlTitle()}</div>
					)}
					{!showSelectMemberInstructions && (
						<SearchUrlSection
							searchType={SearchUrlType.Nurture}
							numPeople={memberSearchList?.data?.totalCount}
							updateDate={campaignMember?.lastSearchUrlUpdate}
							url={campaignMember?.searchUrl}
							searchStatus={searchStatus}
							isInitialSearch={isInitialSearch}
							isLoading={!hasSearchListStatusBeenChecked}
							showVideoLink={!Config.isAgency}
							saveAndSearchButton={saveAndSearchButton}
							onVideoLinkClicked={handleSearchVideoLinkClick}
							saveDisableWarning={
								!linkedInMeta?.isLoggedIn && isSearchFeatureOn && disabledWarning
							}
						/>
					)}
				</Card>
			)}
			{!showSelectMemberInstructions ? (
				<>
					{showEmptyPlaceholder ? (
						<EmptyNurtureListPlaceholder
							sectionTitle={getSearchTableTitle()}
							onGetStartedClick={handleInstructionsGetStartedClick}
						/>
					) : (
						<ProspectTable
							tableTitle={getSearchTableTitle()}
							isLoading={
								isBlocklistRefactorEnabled
									? isSearchListV2Loading
									: memberSearchList?.isFetching
							}
							data={getNurtureSearchListData()}
							initialColumns={NurtureTableColumns}
							pagination={getPagination()}
							searchPlaceholder={SearchInputPlaceholder.Name}
							onSearch={
								isBlocklistRefactorEnabled ? onUpdateSearchTerm : setSearchTerm
							}
							isRowSelectable={SelectableTable[ProspectTableType.Nurture]}
							handleRowClick={handleTableRowClick}
							onBatchRemoveProspect={(connIds) => {
								removeConnection(connIds);
							}}
							onChange={onUpdateTable}
							onCreateLink={determineLinkDisplay}
						/>
					)}
				</>
			) : (
				<div className={styles.instructionWrapper}>
					<InstructionSteps
						title={MANAGE_SEARCH_URL_STEPS_TITLE}
						steps={ManageSearchUrlInstructionSteps}
						stepWidth={215}
						showShadow
					/>
				</div>
			)}
		</>
	);
}
